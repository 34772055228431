import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.5 }
    );

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  const email = ['hello', '@', 'irene.international'].join('');
  const subject = 'Contact Us';
  const body = 'Hello, I would like to know more about your services.';
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  const handleContactClick = () => (window.location.href = mailtoLink);

  return (
    <div className='App'>
      {/* Logo Section */}
      <img src={logo} className='App-logo' alt='logo' />

      {/* Green Section */}
      <div className='green-section'>
        <h1 className='hidden'>
          Ireland’s independent <br className='mobile-break-word' />creative SUPERGROUP.
          <br />
          strategy, design, branding,  <br className='mobile-break-word' />digital & software;
          <br className='hide-break-mobile' /> GLOBALLY RECOGNISED, LOCALLY SOURCED.
        </h1>
        <p className='hidden'>
          The creative motherland.
          <br /> where trust and collaboration come first.
        </p>
      </div>

      {/* White Section */}
      <div className='white-section'>
        <p className='hidden'>
          <span className='underline-text'>35 creative experts</span>,
          <br className='mobile-break-word' />
          {' '}<span className='underline-text'>12 disciplines</span>,
        
          {' '}<span className='underline-text'>1 network</span>.
          <br />
          Founded by Big+Bold, Hundred Studio,  <br className='hide-break-mobile' />Sugar Rush, and Mashmob.
        </p>
      </div>

      {/* Black Section */}
      <div className='black-section'>
        <div />
        <p className='hidden'>FOR RESULTS THAT COUNT.</p>
        <button className='contact-button hidden' onClick={handleContactClick}>
          Contact Us
        </button>
      </div>

      {/* Footer */}
      <div className='footer'>
        <p>Irene international © 2024. NI721211.</p>
      </div>
    </div>
  );
}

export default App;
